import type { AccountingEntryColumnField } from "@/types/utils";

const arOptions: AccountingEntryColumnField[] = [
  "category",
  "seller",
  "user",
  "product",
  "tax",
  "currency",
  "receipt",
  "customer",
  "paymentmethod",
];

const arLabelOptions = (addEmpty = false) => {
  const arOptionList = [...arOptions];

  if (addEmpty) {
    arOptionList.unshift("none");
  }

  return arOptionList.map((option) => ({
    text: `accounting.${option === "tax" ? "accounttax" : option}`,
    value: option === "none" ? null : option,
  }));
};

export { arOptions, arLabelOptions };
